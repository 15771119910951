import React from "react"
import { Link } from "gatsby"
const PostLink = ({ post }) => (
    <div className="py-8 flex flex-wrap md:flex-nowrap items-center">
          <div className="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col p-6">
            <Link to={post.frontmatter.path} className="cursor-pointer">
              <img className="w-full" src={ post.frontmatter.image } alt=""/>
            </Link>
          </div>
          <div className="md:flex-grow">
            <Link to={post.frontmatter.path} className="text-2xl font-medium text-gray-700 title-font mb-2">{post.frontmatter.title}</Link>
            <p className="leading-relaxed">{post.frontmatter.date}</p>
            <Link to={post.frontmatter.path} className="text-red-700 inline-flex items-center mt-4 cursor-pointer">Learn More
              <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path d="M5 12h14"></path>
                <path d="M12 5l7 7-7 7"></path>
              </svg>
            </Link>
          </div>
        </div>
)
export default PostLink